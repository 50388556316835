<template>
  <v-container fluid
    class="scoreTables"
    style="max-width: 100% !important"
  >
    <v-card-text class="pt-0" >
      <v-tabs v-model="selectedTab">
        <v-tab :class="{ 'active-tab2': selectedTab === 0 }"> Xичээлүүд</v-tab>
        <v-tab :class="{ 'active-tab2': selectedTab === 1 }" > Сурагчид</v-tab>
        <v-tab :class="{ 'active-tab2': selectedTab === 2 }">Xуваарь</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="selectedTab" style="background-color: white">
        <v-tab-item style="background-color: white" class="mt-4">
         <MyNClassesLessons  :teacherId="selectedTeacher.PERSON_ID" :zSchool="school"></MyNClassesLessons>
        </v-tab-item>
        <v-tab-item style="background-color: white" class="mt-4">
         <MyNClassesStudents  :teacherId="selectedTeacher.PERSON_ID" :zSchool="school"></MyNClassesStudents>
        </v-tab-item>
        <v-tab-item style="background-color: white" class="mt-4">
          <ClassGroupCalendary
            :zSelectedTeacher="selectedTeacher"
          ></ClassGroupCalendary>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ClassGroupCalendary from "@/sms/huvaari/ClassGroupCalendary.vue";
import MyNClassesLessons from "@/views/Pages/MyClass/MyNClassesLessons.vue";
import MyNClassesStudents from "@/views/Pages/MyClass/MyNClassesStudents.vue";
// const fb = require("@/firebaseConfig.js");
export default {
  components: {
    ClassGroupCalendary,
    MyNClassesStudents,
    MyNClassesLessons
  },
  data: () => ({
    selectedTab: 0,
  }),
  props: {
    selectedTeacher: {
      required: true,
      type: Object,
    },
    school: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
};
</script>
<style>
.active-tab2 {
  background-color: #F8BBD0; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>