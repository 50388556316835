<template>
  <v-app style="background: white !important">
    <v-card class="my-6" style="background-color: white !important">
      <v-tabs v-model="selectedTab" style="background: white !important">
        <v-tab> Багшийн бүртгэл </v-tab>
        <v-tab> ЗАН </v-tab>
        <v-tab> Шилжсэн багшийн бүртгэл</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab" style="background: white !important">
        <v-tab-item>
          <TeachersTable
            :path="path"
            addButtonTitle="Багш"
            :directGo="true"
            :showAddButton="true"
            :query="query"
          ></TeachersTable>
        </v-tab-item>
        <v-tab-item>
          <zan-list
            :item="citem"
            v-for="(citem, index) in [
              {
                name: 'Зааx арга зүйн нэгдлүүд',
                fbpath: this.userData.school.path + '/zanLists',
              },
            ]"
            :key="'zanLists' + index"
          ></zan-list>
        </v-tab-item>
        <v-tab-item>
          <TeachersTable
            :path="path"
            addButtonTitle="Шилжсэн багш нар"
            :directGo="true"
            :showAddButton="true"
            :query="query"
            :showMovedTeachers="true"
          ></TeachersTable>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-app>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import TeachersTable from "./Widgets/TeachersTable";
import { sync } from "vuex-pathify";
export default {
  components: {
    TeachersTable,
    ZanList: () => import("@/sms/admins/ZanList"),
  },
  data: () => ({
    query: null,
    path: null,
    selectedTab: 0,
    zanList: null,
  }),
  watch: {
    selectedTab(val) {
      console.log(val);
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    this.query = this.userData.school.ref
      .collection("teachers")
      .where("role", "==", "teacher")
      .where("deleted", "==", false)
      .orderBy("FIRST_NAME", "asc");
  },
  methods: {},
};
</script>
 